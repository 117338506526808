.glider {
  width: 95%;
  margin: 0;
  padding: 1px;
  display: flex;
  align-items: center;
  position: relative;
  overflow-x: scroll !important;
  border-radius: 8px;
  height: 100%;
}

.glide {
  min-width: 100px;
  height: 105px;
  transition: 0.5s;
}

.moveBehind {
  /*position: absolute;*/
  /*background: none;*/
  /*transform: translateY(-48%);*/
  /*left: 10vw;*/
  font-size: 20px;
}

.moveAhead {
  /*position: absolute;*/
  /*background: none;*/
  /*left: 93vw;*/
  /*transform: translateY(-48%);*/
  font-size: 20px;
}

.pointer {
  cursor: pointer;
  font-size: 20px;
  top: 11%;
  color: #404040;
}

.cursor-pointer {
  cursor: pointer;
}

.tab {
  background: #f1f1f1;
  border-radius: 8px 8px 0 0;
  width: 27vw;
  height: 45px;
  color: #9e9d9d;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  padding: 12px;
  text-align: center;
}

.tab-active {
  background: #ffffff;
  border-bottom: 2px solid #171717;
  font-weight: bold;
  color: #171717;
}

.tab-menu-active {
  background: #f0f2fa;
  border-bottom: #030229 !important;
}

.tab-menu {
  padding: 20px;
}

.product-card {
  background: #ffffff;
  border: 1px solid #717171;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 10px 13px 0 #1126920d;
}

.category-modal {
  margin-top: 10px;
  padding: 10px 8px;
  background: #fafafb;
  border-radius: 8px;
}

.label-modal {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #030229;
  opacity: 0.7;
}

.label-value-modal {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #030229;
  opacity: 0.7;
  margin: 0;
}

.label-value-modal-light {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #030229;
  opacity: 0.7;
}

.button-modal {
  margin-right: 10px;
}

.packed-rounded {
  cursor: pointer;
  width: 31px;
  height: 31px;
  border: 1px solid #717171;
  padding: 10px;
  border-radius: 50%;
  /*position: absolute;*/
  /*margin-top: 1vw;*/
  /*margin-left: 14vw;*/
}

/*@media only screen and (min-width: 1180px){*/
/*    .packed-rounded {*/
/*        margin-left: 17vw;*/
/*    }*/
/*}*/

.packed-rounded-selected {
  background: #605bff;
}

.MuiDropzoneArea-textContainer {
  display: none;
}

.display-flex {
  display: flex;
}

.display-flex-space-between {
  display: flex;
  justify-content: space-between;
}

.form-label {
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 1vh;
}

.css-1i9wt8r-MuiCardContent-root:last-child {
  padding-bottom: 0 !important;
}

.h-scroll {
  -ms-overflow-style: none;
}

.h-scroll::-webkit-scrollbar {
  display: none;
}
